<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-grid">
          <div class="p-col-5">
            <img
              alt="Specfile.pl"
              src="../assets/logo.png"
              class="specfile-logo"
            />
          </div>
          <div v-if="translation" class="p-col-7 locale-dropdown">
            <Dropdown
              v-model="locale"
              :options="langs"
              optionLabel="name"
              :filter="false"
              placeholder="Set Language"
              :showClear="false"
              @change="changeLocale"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <img
                    :src="'/flags/' + slotProps.value.value + '.png'"
                    class="flag-icon"
                  />
                  {{ slotProps.value.name }}
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <img
                    :src="'/flags/' + slotProps.option.value + '.png'"
                    class="flag-icon"
                  />
                  {{ slotProps.option.name }}
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Fieldset :legend="$t('widget_title')">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div class="card">
          <div class="card card-w-title">
            <div
              class="specfile-webclient-widget"
              id="SpecfileWebclientWidgetBlock"
            >
              <div
                class="specfile-webclient-widget-container dropbox"
                style="height: 100%; width: 100%;"
                id="dropbox"
              >
                <img
                  class="buttonBack iconBack"
                  src="/widget/img/back.png"
                  id="btnReturnBack"
                />
                <div class="dropdown-title" style="display:none">
                  <p class="widget-email">{{$t('email_to')}}:</p>
                  <select
                    class="dropdown-recipients-new"
                    id="recipients-list"
                  ></select>
                  <i id="window-info-popup" class="fa fa-info-circle"></i>
                </div>
                <div class="loader p-text-center">
                  <ProgressSpinner style="width:50px;height:50px" strokeWidth="3"  animationDuration=".9s"/>
                  <p>{{$t('loading_recipient')}}</p>
                </div>
                <form
                  accept-charset="UTF-8"
                  id="specfile-webclient-widget-form"
                  enctype="multipart/form-data"
                >
                  <div class="show-step-0" id="specfile-widget-steps">
                    <div class="full-inner-height">
                      <div class="widget-step-0 inner-step0-content">
                        <div class="inner-form-content">
                          <div class="textarea-container">
                            <label for="widgetMessageBody" class="p-mb-2">{{$t('add_text')}}</label>
                            <Textarea
                                    :autoResize="true"
                                    rows="2"
                                    cols="30"
                                    id="widgetMessageBody"
                                    required="true"
                                    name="body"
                            />
                          </div>

                          <div class="files inputFileBgColor">
                            <input
                              type="file"
                              name="fileNames[]"
                              id="customFile"
                              multiple="multiple"
                              class="custom-file-input"
                            />
                            <div class="upload-input-content">
                              <img
                                class="upload-icon"
                                src="/widget/img/drag-and-drop.png"
                              />
                              {{ $t('drag_here') }}
                              <b
                                ><u>{{ $t('choose_file') }}</u></b
                              >
                            </div>
                          </div>
                          <div class="file-list-container">
                            <span id="totalUploadedFiles"></span>
                            <ul
                              id="fileList"
                              class="list-group encrypt-files-list remove-file-uploaded"
                            >
                              <li class="list-group-item normal-padding">
                                {{ $t('no_attachments') }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="submit-buttons-container widget-footer">
                          <button
                            id="sendApplicationStep0"
                            data-step-id="sendApplicationStep0"
                            class="btn-original btn-original-primary submit-buttons"
                          >
                            {{ $t('submit_btn_title') }}
                          </button>
                        </div>
                      </div>

                      <div class="widget-step-2 inner-step2-content">
                        <label
                          for="widgetEmailInput"
                          class="email-notes-label"
                          style="display: none;"
                        >
                          {{ $t('email_message') }}
                        </label>
                        <div
                          class="form-group widget-email-input-container"
                          style="display: none;"
                        >
                          <input
                            placeholder="E-mail"
                            id="widgetEmailInput"
                            class="form-control widget-email-input"
                            name="email"
                          />
                          <span id="emailValidation" class="d-none">{{
                            $t('email_required')
                          }}</span>
                          <span id="emailValidation2" class="d-none">{{
                            $t('email_wrong_format')
                          }}</span>
                        </div>
                      </div>

                      <div class="widget-step-3 inner-step3-content">
                        <label
                          for="widgetPasswordInput"
                          class="email-password-label"
                          style="display: none;"
                        >
                          Utwórz silne hasło do odszyfrowania ewentualnej
                          odpowiedzi:
                        </label>
                        <div class="form-group" style="display: none;">
                          <input
                            type="password"
                            placeholder="Hasło"
                            id="widgetPasswordInput"
                            class="form-control widget-password-input"
                            name="password"
                          />
                          <span id="passwordValidation" class="d-none">
                            Hasło jest wymagane
                          </span>
                          <span id="passwordValidation2" class="d-none">
                            Hasło musi mieć co najmniej 6 znaków
                          </span>
                          <span id="passwordValidation3" class="d-none">
                            Hasło musi zawierać conajmniej jedną małą i wielką
                            literę!
                          </span>
                          <span id="passwordValidation4" class="d-none">
                            Hasło musi zawierać conajmniej jedną liczbę!
                          </span>
                        </div>
                        <label
                          for="widgetPassword2Input"
                          class="email-password-label"
                          style="display: none;"
                        >
                          Wpisz powtórnie utworzone hasło i zapamiętaj:
                        </label>

                        <div
                          class="form-group widget-email-input-container"
                          style="display: none;"
                        >
                          <input
                            type="password"
                            placeholder="Powtórz hasło"
                            id="widgetPassword2Input"
                            class="form-control widget-password-input"
                            name="password_confirmation"
                          />
                          <span id="password2Validation" class="d-none"
                            >Potwierdzenie hasła jest wymaganie</span
                          >
                          <span id="password2Validation2" class="d-none"
                            >Hasła nie pasują do siebie</span
                          >
                        </div>

                        <label class="email-notes-label" style="display: none;">
                          Serwis specfile.pl utworzy dla Ciebie konto z kluczami
                          szyfrowania dla możliwości przekazywania Tobie
                          szyfrowanej poczty zwrotnej. <br />
                          W przypadku przekazania odpowiedzi odpowiednie
                          Powiadomienie otrzymasz na swój email.
                        </label>
                      </div>

                      <div class="widget-step-4 inner-step4-content">
                        <p class="widget-email-exists" style="display: none;">
                          W przypadku przekazania odpowiedzi odpowiednie
                          Powiadomienie otrzymasz na swój email.
                        </p>
                      </div>

                      <div class="widget-step-info inner-step-info-content">
                        <div class="widget-email-exists" style="display: none;">
                          <span>{{ $t('provide_email_to_recipient') }}</span>
                          <span>{{ $t('email_info') }}</span>
                          <div id="WidgetUserEmailAddress">
                            <div class="form-group">
                              <label
                                for="NotifySenderBtn"
                                class="notify-sender-btn"
                              >
                                <input
                                  type="checkbox"
                                  name="notify_sender"
                                  value="1"
                                  id="NotifySenderBtn"
                                />
                                {{ $t('notification_input_message') }}
                              </label>
                            </div>
                            <div
                              class="form-group widget-email-input-container"
                            >
                              <label for="widgetInfoEmailInput">{{
                                $t('email_label')
                              }}</label>
                              <input
                                placeholder="E-mail"
                                id="widgetInfoEmailInput"
                                class="form-control widget-email-input"
                                name="email"
                              />
                              <span
                                id="widgetInfoEmailValidation"
                                class="d-none"
                                >{{ $t('email_required') }}</span
                              >
                              <span
                                id="widgetInfoEmailValidation2"
                                class="d-none"
                                >{{ $t('email_wrong_format') }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group submit-buttons-container widget-footer"
                          style="display: none;"
                        >
                          <button
                            class="email-buttons btn-original"
                            id="ContinueSendingFiles"
                            value="1"
                          >
                            {{ $t('continue_sending') }}
                          </button>
                        </div>
                      </div>

                      <div class="widget-step-1 inner-step1-content">
                        <div
                          class="form-group submit-buttons-container widget-footer"
                          style="display: none;"
                        >
                          <button
                            class="email-buttons btn-original"
                            id="sendApplication"
                          >
                            wyślij bez możliwości odpowiedzi
                          </button>
                          <button
                            class="email-buttons btn-original"
                            id="sendApplicationWait"
                          >
                            czekaj na odpowiedź
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="specfile-widget-meter"
                      id="progressBar"
                      style="display: none;"
                    >
                      <span id="send-progress" style="width: 0%"></span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fieldset>
</template>

<script>
// import { axios } from 'axios';
export default {
  name: 'Messenger',
  created() {
    localStorage.setItem('currentLang', 'pl')
    localStorage.setItem(
      'envVariables',
      JSON.stringify({
        scriptToken: null,
        appUrl: process.env.VUE_APP_APP_URL || 'https://staging.web.specfile.pl',
        apiUrl: process.env.VUE_APP_API_URL || 'https://api.staging.specfile.pl',
        maxUploadSize: process.env.VUE_MAX_UPLOAD_SIZE || 5242880,
        trans: {
          pl: require('../i18n/pl.json'),
          en: require('../i18n/en.json'),
          de: require('../i18n/de.json'),
          ru: require('../i18n/ru.json'),
        },
      })
    );
    this.$i18n.locale = 'pl-PL';
  },
  mounted() {
    var tag = document.createElement('script');
    tag.src = `/widget/widget.js?token=${this.token}&lang=${this.locale.value}&ts=43531453`;
    tag.async = true;
    document.head.appendChild(tag);
  },
  data() {
    return {
      token: this.$route.params.token,
      locale: { name: 'Polski', code: 'pl-PL', value: 'pl' },
      locales: ['pl', 'en', 'de', 'ru'],
      langs: [
        { name: 'Polski', code: 'pl-PL', value: 'pl' },
        { name: 'English', code: 'en-EN', value: 'en' },
        { name: 'Deutsche', code: 'de-DE', value: 'de' },
        { name: 'Russian', code: 'ru-RU', value: 'ru' },
      ],
      translation: false,
    };
  },
  methods: {
    changeLocale(event) {
      this.locale = event.value;
      this.$i18n.locale = this.locale.code;
      localStorage.setItem('currentLang', event.value.value)
    },
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped></style>
