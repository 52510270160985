import { createRouter, createWebHashHistory } from 'vue-router';
import Messenger from './components/Messenger.vue';

const routes = [
    {
        path: '/:token',
        name: 'messenger',
        component: Messenger,
        props: true
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
